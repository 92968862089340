import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FfkLogo from "../../assets/images/ffk-logo.png";
import styles from "./NavigationBar.module.scss";
import {
  DownOutlined,
  MenuOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import i18n from "../../i18n";
import useFetch from "../../hooks/useFetch";
import { CategoriesResponse, SubCategory } from "../../types/types";
import { Link, useNavigate } from "react-router-dom";
import { replaceStringSpaces } from "../../utils/utils";
import Albania from "../../assets/images/albania.png";
import England from "../../assets/images/united-kingdom (1).png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const NavigationBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useFetch<CategoriesResponse>("/categories/top-level");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return width ? width >= 768 : false;
  }, [width]);

  const handleMenuClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as HTMLElement)
    ) {
      setIsMenuOpen(false);
      setOpenCategory(null);
    }
  }, []);

  const handleOpenCategory = useCallback((categoryName: string) => {
    setOpenCategory((prevCategory) =>
      prevCategory === categoryName ? null : categoryName,
    );
  }, []);

  const handleLanguageChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language).finally();
    },
    [i18n],
  );

  const handleFFKCategoryNavigation = useCallback(
    (categoryName: string) => {
      if (categoryName.includes("Komiteti")) {
        navigate("/committee");
      } else if (categoryName.includes("Administrata")) {
        navigate("/administration");
      } else if (categoryName.includes("Presidenti")) {
        navigate("/presidenti");
      } else if (categoryName.includes("Komisionet")) {
        navigate("/committees");
      } else if (categoryName.includes("Sekretari")) {
        navigate("/sekretar-i-pergjithshem");
      } else if (categoryName.includes("Normative")) {
        navigate("/normative-acts");
      }
    },
    [navigate],
  );

  //We need category because backend requires the id of category and the id of clicked subcategory
  //TODO: handle 'Lajmet' & 'Te tjerat' navigation
  const handleNavigation = useCallback(
    (category: SubCategory, clickedSubCategory: SubCategory) => {
      const state = {
        category: category,
        subCategory: clickedSubCategory,
      };

      if (openCategory === "GARAT") {
        navigate(`/garat/${replaceStringSpaces(clickedSubCategory.name)}`, {
          state: { categoryId: '68eadca6-5484-433d-8b7d-7a9903b24642' },
        });
      } else if (openCategory === "FFK") {
        handleFFKCategoryNavigation(clickedSubCategory.name);
      } else if (openCategory === "TË TJERAT") {
        if (clickedSubCategory.name === "GRASSROOTS") {
          navigate("/grassroots", {
            state: state,
          });
        } else if (clickedSubCategory.name === "PLAYMAKERS") {
          navigate("/playmakers", {
            state: state,
          });
        }
      } else {
        navigate("/lajmet", {
          state: state,
        });
      }
    },
    [openCategory, navigate, handleFFKCategoryNavigation],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.NavigationBar}>
      {isMobile && (
        <Link className={styles.LogoImage} to="/">
          <img src={FfkLogo} alt="ffk-logo" />
        </Link>
      )}
      <div className={styles.NavigationBarAlignment}>
        <div className={styles.NavigationBarM}>
          <div className={styles.NavigationBarMain}>
            {/* Navigation Section */}
            <div className={styles.NavigationBarItems}>
              <Link to="#">{t("app.navigation.biletaria", "Biletaria")}</Link>
              <Link to="#">{t("app.navigation.ffkshop", "FFK Shop")}</Link>
              <a
                href="https://ffk-media.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("app.navigation.akreditimi", "Akreditimi")}
              </a>
            </div>
            {/* Language and Search Section */}
            <div className={styles.NavigationBarLanguages}>
              <Link to="/fanClub">{t("app.fanClub.title", "Fan Club")}</Link>
              <Link to="/volunteers">
                {t("app.volunteers.program", "Programi i Vullnetarëve")}
              </Link>
              <span onClick={() => handleLanguageChange("sq")}>
                <img width={20} src={Albania} />
              </span>{" "}
              |{" "}
              <span onClick={() => handleLanguageChange("en")}>
                <img width={20} src={England} />
              </span>
            </div>
          </div>
          <div className={styles.NavigationBarSearch}>
            <SearchOutlined />
          </div>
        </div>
        {/*Menu */}
        {!isMobile ? (
          <div style={{ display: "flex" }}>
            {!isMenuOpen && (
              <Link className={styles.LogoImage} to="/">
                <img src={FfkLogo} alt="ffk-logo" />
              </Link>
            )}
            <div
              ref={menuRef}
              className={classNames(styles.NavigationBarMenu, {
                [styles.NavigationBarMenuOpen]: isMenuOpen,
              })}
              onClick={(e) => handleMenuClick(e)}
            >
              {!isMenuOpen ? (
                <>
                  <MenuOutlined style={{ fontSize: "15px" }} />
                  <p>{t("app.navigation.menu", "Menu")} </p>
                </>
              ) : (
                <div className={styles.NavigationLinksMenu}>
                  {data?.map((item) => {
                    return (
                      <h4 onClick={() => handleOpenCategory(item.name)}>
                        {item.name}
                        {openCategory === item.name ? (
                          <UpOutlined />
                        ) : (
                          <DownOutlined />
                        )}
                      </h4>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div
              ref={menuRef}
              className={classNames(styles.NavigationBarMenu, {
                [styles.NavigationBarMenuOpen]: isMenuOpen,
              })}
              onClick={(e) => handleMenuClick(e)}
            >
              {!isMenuOpen ? (
                <>
                  <MenuOutlined style={{ fontSize: "15px" }} />
                  <p>{t("app.navigation.menu", "Menu")} </p>
                </>
              ) : (
                <div className={styles.NavigationLinksMenu}>
                  {data?.map((item) => {
                    return (
                      <h4 onClick={() => handleOpenCategory(item.name)}>
                        {item.name}
                        {openCategory === item.name ? (
                          <UpOutlined />
                        ) : (
                          <DownOutlined />
                        )}
                      </h4>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {openCategory !== null && (
          <div
            className={classNames(styles.NavigationLinksMenuItem, {
              [styles.GaratMenu]:
                openCategory === "GARAT" || openCategory === "FFK",
            })}
          >
            {data
              ?.find((item) => item.name === openCategory)
              ?.subCategories.map((subCat) => (
                <div className={styles.NavigationLinksMenuItemSubCat}>
                  <h4>{subCat.name}</h4>
                  {subCat.subCategories.map((cat) => (
                    <h5 onClick={() => handleNavigation(subCat, cat)}>
                      {cat.name}
                    </h5>
                  ))}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
