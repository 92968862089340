import React, { useState } from "react";
import VolunteersImage from "../../assets/images/volunteers.png";
import styles from "./Volunteers.module.scss";
import { useTranslation } from "react-i18next";
import StepThree from "../../components/volunteer/StepThree";
import {Steps} from "antd";
import VolunteerForm from "../../components/volunteer/volunteer-form/VolunteerForm";
import StepOne from "../../components/volunteer/StepOne";

const { Step } = Steps;

const Volunteers = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const onChangeStep = (current: number) => {
    setCurrentStep(current);
  };

  return (
      <div className={styles.VolunteersContainer}>
        <img className={styles.VolunteersPhoto} src={VolunteersImage} alt="Volunteers"/>
        <div className= {styles.VolunteerStepper}>

          <Steps
              current={currentStep}
              onChange={onChangeStep}
              progressDot
              direction="horizontal"
              responsive={false}
          >
            <Step />
            <Step />
            <Step />
          </Steps>

          {currentStep === 0 && <StepOne setCurrentStep={setCurrentStep} />}
          {currentStep === 1 && <VolunteerForm setCurrentStep={setCurrentStep} />}
          {currentStep === 2 && <StepThree />}
        </div>
      </div>
  );
};

export default Volunteers;
