import React from "react";
import styles from "./Spinner.module.scss";

const Spinner = () => {
    return (
        <div className={styles.SpinnerOverlay}>
            <div className={styles.Spinner} />
        </div>
    );
};

export default Spinner;
