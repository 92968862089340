import React, { useMemo } from "react";
import styles from "./Competition.module.scss";
import { useLocation, useParams } from "react-router-dom";
import AlbiMallSuperligaImage from "../../assets/images/albi-mall-superliga.png";
import logo from "../../assets/images/ffk-logo.png";
import { Avatar, Table } from "antd";
import useFetch from "../../hooks/useFetch";

const Competition = () => {
  const { title } = useParams();
  const { state } = useLocation();

  const weeks = [{ weekNumber: 2 }, { weekNumber: 1 }];

  const referenceLeagueId = state?.categoryId;

  const {
    data: standingsData,
    loading: standingsLoading,
    error: standingsError,
  } = useFetch<any>(`/standings/league/${referenceLeagueId}`);

  const {
    data: matchesData,
    loading: matchesLoading,
    error: matchesError,
  } = useFetch<any>(`/matches/${referenceLeagueId}/week/` + 2);

  const formattedTitle = useMemo(() => {
    return title?.replace(/-/g, " ").toUpperCase();
  }, [title]);

  const standingsColumns = [
    {
      title: "CLUB",
      dataIndex: "teamName",
      key: "teamName",
      render: (text: string, record: any) => (
        <div className={styles.ClubsColumn}>
          <Avatar
            shape="square"
            size="small"
            icon={<img src={record.logoUrl} alt="club" />}
          />
          <span style={{ marginLeft: 8 }}>{text}</span>
        </div>
      ),
    },
    { title: "MP", dataIndex: "matchesPlayed", key: "matchesPlayed" },
    { title: "W", dataIndex: "wins", key: "wins" },
    { title: "D", dataIndex: "draws", key: "draws" },
    { title: "L", dataIndex: "losses", key: "losses" },
    { title: "GF", dataIndex: "goalsScored", key: "goalsScored" },
    { title: "GA", dataIndex: "goalsAgainst", key: "goalsAgainst" },
    { title: "GD", dataIndex: "goalDifference", key: "goalDifference" },
    {
      title: "Pts",
      dataIndex: "points",
      key: "points",
      render: (pts: number) => <span style={{ color: "green" }}>{pts}</span>,
    },
  ];

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
    const formattedTime = date.toTimeString().slice(0, 5);
    return `${formattedDate} - ${formattedTime}`;
  };

  const matchesColumns = [
    {
      key: "dateLeague",
      render: (record: any) => (
        <div className={styles.MatchCard}>
          <div className={styles.MatchInfo}>
            <span>{formatDate(record.date)}</span>
            <h4>{record.leagueName || "ALBI MALL SUPERLIGA"}</h4>
          </div>
          <div className={styles.MatchTeams}>
            <div className={styles.Team}>
              <img
                src={record.homeTeamLogo || logo}
                alt={record.homeTeamName}
              />
              <span>{record.homeTeamName}</span>
            </div>
            <div className={styles.Score}>
              {record.homeTeamScore !== null && record.awayTeamScore !== null
                ? `${record.homeTeamScore} - ${record.awayTeamScore}`
                : "VS"}
            </div>
            <div className={styles.Team}>
              <img
                src={record.awayTeamLogo || logo}
                alt={record.awayTeamName}
              />
              <span>{record.awayTeamName}</span>
            </div>
          </div>
        </div>

      ),
    },
  ];

  if (standingsLoading || matchesLoading) {
    return <div>Loading...</div>;
  }

  if (standingsError || matchesError) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>{formattedTitle}</h1>
      </div>
      <div className={styles.ContentWrapper}>
        <img
          style={{ width: "1725px" }}
          src={AlbiMallSuperligaImage}
          alt="league-image"
        />
        <div style={{marginTop: '3rem'}}>
          <div style={{float: 'left', textAlign: 'left', marginBottom: '1rem'}}>
            <h2>{formattedTitle}</h2>
            <p>Tabela</p>
          </div>
          <div className={styles.TableContent}>
            <Table
              columns={standingsColumns}
              dataSource={standingsData}
              pagination={false}
            />
          </div>
        </div>

        <div className={styles.MainWrapper}>
          <div className={styles.MatchesWrapper}>
            {weeks.map(({ weekNumber }) => (
              <div key={weekNumber} className={styles.MatchesContainer}>
                <h2>Java e {weekNumber}</h2>
                <Table
                  columns={matchesColumns}
                  dataSource={matchesData.filter(
                    (match: any) => match.weekNumber === weekNumber,
                  )}
                  pagination={false}
                  rowKey={(record) => record.id}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competition;
