import React, {useState} from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/home/Home";
import styles from "./App.module.scss";
import Competition from "./containers/competitions/Competition";
import PlaymakersGrassroots from "./containers/playmakers-glassroots/PlaymakersGrassroots";
import FanClub from "./containers/fan-club/FanClub";
import Administration from "./containers/administration/Administration";
import Volunteers from "./containers/volunteers/Volunteers";
import ScrollToTop from "./containers/scroll-to-top/ScrollToTop";
import Intermediaries from "./containers/intermediaries/Intermediaries";
import Committees from "./containers/committees/Committees";
import Committee from "./containers/committee/Committee";
import Presidenti from "./containers/presidenti/Presidenti";
import NormativeActs from "./containers/normative-acts/NormativeActs";
import News from "./containers/news/News";
import NewsDetails from "./containers/news-details/NewsDetails";
import NotFound from "./containers/not-found/NotFound";

const App = () => {
  return (
      <div className={styles.RoutesWrapper}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/intermediary" element={<Intermediaries />} />
          <Route path="/committees" element={<Committees />} />
          <Route path="/committee" element={<Committee />} />
          <Route path="/presidenti" element={<Presidenti />} />
          <Route path="/sekretar-i-pergjithshem" element={<Presidenti />} />
          <Route path="/normative-acts" element={<NormativeActs />} />
          <Route path="/garat/:title" element={<Competition />} />
          <Route path="/lajmet" element={<News />} />
          <Route path="/lajmet/:title" element={<NewsDetails />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/volunteers" element={<Volunteers />} />
          <Route path="/fan-club" element={<FanClub />} />
          <Route path="/playmakers" element={<PlaymakersGrassroots />} />
          <Route path="/grassroots" element={<PlaymakersGrassroots />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
  );
};

export default App;
