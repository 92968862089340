import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import styles from "./TeamsActivity.module.scss";
import {NewsResponse, PaginatedNewsResponse} from "../../../types/types";
import NewsCard from "../../../components/_shared/news-card/NewsCard";

type TeamsActivityProps = {
  team: "MAN" | "WOMAN";
};
const TeamsActivity = ({ team }: TeamsActivityProps) => {
  const { t } = useTranslation();

  const teamIds = useMemo(() => {
    return team === "MAN"
      ? "50bc1691-dd5a-47fe-be40-678ca1b08348"
      : "7accd459-e6e1-48fa-9483-e2b2d02af9f4";
  }, [team]);

  const { data } = useFetch<PaginatedNewsResponse>(`/posts?subcategoryId=${teamIds}`);

  if (!data || data.content.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.MensActivity}>
      <h1>
        {team === "MAN"
          ? t("app.home.mensActivity", "Aktivitetet e ekipit të meshkujve")
          : t("app.home.womensActivity", "Aktivitetet e ekipit të femrave")}
      </h1>
      <div className={styles.NewsCardContainer}>
        {data.content.slice(0, 3).map((news) => (
          <NewsCard {...news} />
        ))}
      </div>
    </div>
  );
};

export default TeamsActivity;
