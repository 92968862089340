import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from "./Volunteer.module.scss";

const StepThree = () => {
  const navigate = useNavigate();

  const handleFinish = () => {
    navigate("/"); // Redirect to the homepage
  };

  return (
      <div className={styles.StepOneContainer}>
        <h1>Faleminderit për Interesimin Tuaj!</h1>
        <p>
          Aplikimi juaj është dorëzuar me sukses. Ne ju falënderojmë për interesimin tuaj për t'u bërë pjesë e Programit të Vullnetarëve në FFK.
        </p>
        <br />
        <p>
          Së shpejti do të shqyrtojmë aplikimin tuaj dhe, nëse pranoheni ose refuzoheni për programin, do të merrni automatikisht një email informues me statusin tuaj.
        </p>
        <div className={styles.ButtonAlignment}>
        <Button type="primary" onClick={handleFinish} className={styles.ButtonStyle}>
          Perfundo
        </Button>
        </div>
      </div>
  );
};

export default StepThree;
