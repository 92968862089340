import React, {useMemo} from "react";
import styles from "./Home.module.scss";
import {useTranslation} from "react-i18next";
import useFetch from "../../hooks/useFetch";
import {NationalGamesResponse} from "../../types/types";
import HeroSection from "../../components/hero-section/HeroSection";
import GameCard from "../../components/game-card/GameCard";
import MainNews from "../../components/main-news/MainNews";
import CompetitionGameCard from "../../components/competition-game-card/CompetitionGameCard";
import Gallery from "../../components/gallery/Gallery";
import VideoCard from "../../components/video-card/VideoCard";
import Partners from "../../components/partners/Partners";
import RightArrow from "../../assets/images/arrow-right.png";
import TeamsActivity from "./teams-activity/TeamsActivity";
import Spinner from "../../components/_shared/loader/Spinner";

const Home = () => {
  const {t} = useTranslation();

  const isMobile = window.innerWidth >= 768;

  const {data: nationalGames, loading} = useFetch<NationalGamesResponse>(
      "/national-games/next-three-games"
  );

  const sortedNationalGames = useMemo(() => {
    const order = {UPCOMING: 1, LIVE: 2, FINISHED: 3};
    return [...(nationalGames ?? [])].sort(
        (a, b) => order[a.status] - order[b.status]
    );
  }, [nationalGames]);

  if (loading) {
    return <Spinner />;
  }

  return (
      <>
        <div className={styles.HomeContainer}>
          <HeroSection/>

          {sortedNationalGames.length > 0 && (
              <div className={styles.NationalGames}>
                <h1>{t("app.home.nationalGames", "Lojërat kombëtare")}</h1>
                <div className={styles.GameCardContainer}>
                  {sortedNationalGames?.map((game) => (
                      <GameCard key={game.id} {...game} />
                  ))}
                </div>
              </div>
          )}

          {/* Main news Section */}
          <div>
            <MainNews/>
          </div>

          {/* Men's Activity */}
          <TeamsActivity team="MAN"/>

          {/* Competition Games */}
          <div className={styles.NationalGames}>
            <h1>{t("app.home.competitionGames", "Lojërat kompetitive")}</h1>
            <div className={styles.CompetitionGameCard}>
              <CompetitionGameCard/>
              <CompetitionGameCard/>
              <CompetitionGameCard/>
              <CompetitionGameCard/>
              <CompetitionGameCard/>
            </div>
          </div>

          {/* Women's Activity */}
          <TeamsActivity team="WOMAN"/>

          {/* Photo Gallery */}
          <div>
            <Gallery/>
          </div>

          {/* Video Section */}
          {isMobile ? (
              <div className={styles.VideoSection}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <h1>{t("app.home.watchOurVideos", "Shikoni Videot Tona")}</h1>
                  <div className={styles.VisitChannel}>
                    <a
                        href={"https://www.youtube.com/"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {t("app.home.visitOurChannel", "Për më shumë vizitoni kanalin tonë")}
                    </a>
                    <img src={RightArrow} alt={"Right Arrow"}/>
                  </div>
                </div>
                <div className={styles.VideoCardContainer}>
                  <VideoCard/>
                  <VideoCard/>
                  <VideoCard/>
                </div>
              </div>
          ) : (
              <div className={styles.VideoSection}>
                <div>
                  <h1>{t("app.home.watchOurVideos", "Shikoni Videot Tona")}</h1>
                </div>
                <div className={styles.VideoCardContainer}>
                  <VideoCard/>
                  <VideoCard/>
                  <VideoCard/>
                </div>
                <div className={styles.VisitChannel}>
                  <a
                      href={"https://www.youtube.com/c/FederataeFutbolliteKosov%C3%ABs"}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t("app.home.visitOurChannel", "Për më shumë vizitoni kanalin tonë")}
                  </a>
                  <img src={RightArrow} alt={"Right Arrow"}/>
                </div>
              </div>
          )}

          {/* Partners Section */}
          <div className={styles.PartnerSection}>
            <h1>{t("app.home.Partners", "Partneret tanë")}</h1>
            <Partners/>
          </div>
        </div>
      </>
  );
};

export default Home;
