import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import NavigationBar from "./components/navigation-bar/NavigationBar";
import Footer from "./components/footer/Footer";
import Spinner from "./components/_shared/loader/Spinner";

const Root = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <NavigationBar />
                    <App />
                    <Footer />
                </>
            )}
        </>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </React.StrictMode>,
);
