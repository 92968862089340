import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import NewsCard from "../../components/_shared/news-card/NewsCard";
import useFetch from "../../hooks/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import {NewsResponse, PaginatedNewsResponse} from "../../types/types";
import styles from "./News.module.scss";
import { replaceStringSpaces } from "../../utils/utils";
import DataNotFound from "../../components/_shared/data-not-found/DataNotFound";

const pageSize = 6;
const News = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data, loading } = useFetch<PaginatedNewsResponse>(
    `/posts?subCategoryId=${state?.category?.id}&childSubcategoryId=${state?.subCategory?.id}`,
  );

  const [currentPage, setCurrentPage] = useState(1);

  const mainNews = useMemo(() => {
    return data?.content[0];
  }, [data]);

  const paginatedNews = useMemo(() => {
    if (!data) return [];

    const otherNews = data.content.slice(1);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return otherNews.slice(startIndex, endIndex);
  }, [data, currentPage, pageSize]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleMainNewsNavigation = useCallback(() => {
    const state = {
      state: {
        ...mainNews,
      },
    };
    if (i18n.language === "sq") {
      navigate(
        `/lajmet/${replaceStringSpaces(mainNews?.alTitle ?? "")}`,
        state,
      );
      return;
    }
    navigate(`/lajmet/${replaceStringSpaces(mainNews?.enTitle ?? "")}`, state);
  }, [navigate, mainNews, i18n]);

  return (
    <div className={styles.NewsContainer}>
      {data && data.content.length > 0 ? (
        <>
          <h2>{t("app.news.news", "Informacionet me te reja")}</h2>
          <div
            className={styles.News}
            onClick={() => handleMainNewsNavigation()}
          >
            <img src={mainNews?.mainImageUrl} alt="News image" />
            <div className={styles.MainNews}>
              <h1>
                {i18n.language === "sq" ? mainNews?.alTitle : mainNews?.enTitle}
              </h1>
            </div>
          </div>
          <h1>{t("app.news.main", "Kryesore")}</h1>
          <div className={styles.AllNews}>
            {paginatedNews?.map((newsItem, index) => (
              <NewsCard key={index} {...newsItem} />
            ))}
          </div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data?.content.length - 1}
            onChange={handlePageChange}
            style={{
              display: "flex",
              marginTop: "10rem",
              justifyContent: "center",
            }}
          />
        </>
      ) : (
        <DataNotFound loading={loading} />
      )}
    </div>
  );
};

export default News;
