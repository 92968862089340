import React from "react";
import styles from "./CardRow.module.scss";
import classNames from "classnames";

type CardRowDataType = {
  id?: number;
  title: string;
  other?: string;
  navigationLink?: {
    link: string;
    onLinkClick: () => void;
  };
};

type CardRowType = {
  data: CardRowDataType;
  colors?: "DEFAULT" | "INVERTED" | "BLUE";
  align?: "CENTER" | "LEFT";
  withoutIcon?: boolean;
};
const CardRow = ({
  data: { id, title, other, navigationLink },
  colors = "DEFAULT",
  align = "LEFT",
  withoutIcon = true,
}: CardRowType) => {
  return (
    <div
      className={classNames(styles.CardRowWrapper, {
        [styles.Inverted]: colors === "INVERTED",
        [styles.Blue]: colors === "BLUE",
        [styles.AlignCenter]: align === "CENTER",
      })}
    >
      <p>
        {id && <span>{id}.</span>} {title} <span>{other && other}</span>
      </p>
      {navigationLink && (
        <p
          className={classNames(styles.NavLink, {
            [styles.MarginTop]: align === "CENTER",
          })}
          onClick={navigationLink.onLinkClick}
        >
          {navigationLink.link} {!withoutIcon && <>&#x2192;</>}
        </p>
      )}
    </div>
  );
};

export default CardRow;
