import React from 'react';
import { Button } from 'antd';
import styles from "./Volunteer.module.scss";
import FFKLogo from "../../assets/images/ffk-logo-sm.png";

interface StepOneProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepOne: React.FC<StepOneProps> = ({ setCurrentStep }) => {
  return (
      <div className={styles.StepOneContainer}>
        <img src={FFKLogo} alt="FFK Logo"/>
        <h1>Bëhu Pjesë e Suksesit të Futbollit!</h1>
        <p>
          Programi i Vullnetarëve i Federatës së Futbollit të Kosovës (FFK) është një mundësi unike për të gjithë të rinjtë dhe të rejat që dëshirojnë
          të kontribuojnë në futboll dhe të përfitojnë përvoja të vlefshme për të ardhmen e tyre. Si organizatë jofitimprurëse që ka për qëllim zhvillimin
          e futbollit në Kosovë, FFK organizon programe të ndryshme në bashkëpunim me UEFA-n dhe FIFA-n, duke synuar masivizimin e futbollit dhe përfshirjen
          e sa më shumë të rinjve në aktivitetet tona.
        </p>
        <br />
        <p>
          Përmes programit të vullnetarëve, ju keni mundësinë të kontribuoni në organizimin e ngjarjeve të ndryshme, të krijoni lidhje të reja sociale,
          të përmirësoni aftësitë tuaja profesionale dhe të jeni pjesë e ngjarjeve të FFK-së, që shpesh kanë karakter ndërkombëtar. Si vullnetar,
          ju do të angazhoheni jo vetëm në ditën e ngjarjeve, por edhe në takime dhe trajnime të ofruara nga stafi ynë.
        </p>

        {/* Button to go to VolunteerForm */}
        <div className={styles.ButtonAlignment}>
        <Button
            type="primary"
            onClick={() => setCurrentStep(1)}
            className={styles.ButtonStyle}
        >
          Vazhdo tek forma e aplikimit
        </Button>
        </div>
      </div>
  );
};

export default StepOne;
