import { Carousel } from "antd";
import React from "react";
import useFetch from "../../hooks/useFetch";
import styles from "./HeroSection.module.scss";

interface Post {
    id: string;
    alTitle: string;
    enTitle: string;
    mainImageUrl: string;
}

const HeroSection = () => {
    const { data: posts, loading, error } = useFetch<Post[]>("/posts/last-three-main-posts");

    if (loading) return <div></div>;
    if (error || !posts) return <div>Error loading posts</div>;

    return (
        <Carousel autoplay>
            {posts.map((post) => (
                <div className={styles.Container} key={post.id}>
                    <img src={post.mainImageUrl} alt={post.enTitle} />
                    <div className={styles.MainNews}>
                        <h1>{post.alTitle}</h1>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default HeroSection;
