import React, {useState} from "react";
import {Form, Input, Select, Button, message} from "antd";
import {useTranslation} from "react-i18next";
import {FormValues} from "../../../types/types";
import usePost from "../../../hooks/usePost";
import styles from "./VolunteerForm.module.scss";

const {Option} = Select;

interface VolunteerFormProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const VolunteerForm: React.FC<VolunteerFormProps> = ({setCurrentStep}) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState<FormValues>({
    fullName: "",
    city: "",
    gender: "",
    personalNumber: "",
    email: "",
    age: 0,
  });

  const {post, loading, error} = usePost("/volunteers/apply");

  const cities = [
    "Artana", "Dardana", "Deçani", "Sharri", "Drenasi", "Ferizaji", "Fushë Kosova", "Gjakova",
    "Gjilani", "Graçanica", "Hani Elezit", "Burimi", "Juniku", "Kaçaniku", "Klina", "Kllokoti",
    "Albaniku", "Lipjani", "Malisheva", "Mamusha", "Mitrovica", "Mitrovica Veriore", "Kastrioti",
    "Parteshi", "Peja", "Besiana", "Prishtina", "Prizreni", "Rahoveci", "Shtërpca", "Shtimja",
    "Skënderaji", "Theranda", "Vitia", "Vushtrria", "Zubin Potoku", "Zveçani",
  ];

  const onFinish = async (values: FormValues) => {
    const payload = {
      ...values,
      age: Number(values.age),
    };

    try {
      await post(payload);
      message.success(t("app.volunteers.applicationSubmitted", "Application submitted successfully!"));

      // After form submission, go to the third step
      setCurrentStep(2);
    } catch (err) {
      console.error("Form submission error:", error);
      message.error(t("app.volunteers.applicationError", "There was an error submitting your application."));
    }
  };

  return (
      <div className={styles.VolunteerFormContainer}>
        <h1>Programi i Vullnetareve</h1>
        <p>Formulari i aplikimit do të përmbajë një datë fillimi dhe përfundimi të periudhës së aplikimit,
          si dhe një përshkrim të shkurtër të ngjarjes për të cilën po aplikoni.</p>
        <Form onFinish={onFinish} initialValues={formValues}>
          <div className={styles.VolunteerForm}>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.name&surname", "Emri & Mbiemri")}</p>
              <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      pattern: /^[A-Za-z]+ [A-Za-z]+$/,
                      message: t(
                          "app.volunteers.validation.fullName",
                          "Duhet të jetë vetëm me shkronja dhe të përmbajë dy fjalë, p.sh. Filan Fisteku",
                      ),
                    },
                  ]}
              >
                <Input
                    value={formValues.fullName}
                    onChange={(e) =>
                        setFormValues({...formValues, fullName: e.target.value})
                    }
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.city", "Qyteti")}</p>
              <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      pattern: /^[A-Za-zçÇëË\s]+$/,
                      message: t(
                          "app.volunteers.validation.cityFormat",
                          "Qyteti duhet të përmbajë vetëm shkronja",
                      ),
                    },
                  ]}
              >
                <Select
                    showSearch
                    value={formValues.city}
                    onChange={(value) =>
                        setFormValues({...formValues, city: value})
                    }
                    placeholder="Select a city"
                >
                  {cities.map((city) => (
                      <Option key={city} value={city}>
                        {city}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.gender", "Gjinia")}</p>
              <Form.Item
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                  ]}
              >
                <Select
                    value={formValues.gender}
                    onChange={(value) =>
                        setFormValues({...formValues, gender: value})
                    }
                >
                  <Option value="FEMALE">Femër</Option>
                  <Option value="MALE">Mashkull</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.idNumber", "Numri personal")}</p>
              <Form.Item
                  name="personalNumber"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: t(
                          "app.volunteers.validation.personalNumber",
                          "Numri personal duhet të përmbajë 10 numra",
                      ),
                    },
                  ]}
              >
                <Input
                    value={formValues.personalNumber}
                    onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          personalNumber: e.target.value,
                        })
                    }
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.email", "Email")}</p>
              <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t(
                          "app.volunteers.validation.emailFormat",
                          "Formati i pavlefshëm i email-it.",
                      ),
                    },
                  ]}
              >
                <Input
                    value={formValues.email}
                    onChange={(e) =>
                        setFormValues({...formValues, email: e.target.value})
                    }
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.age", "Age")}</p>
              <Form.Item
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value < 18) {
                          return Promise.reject(
                              new Error(
                                  t(
                                      "app.volunteers.validation.ageGreaterThan18",
                                      "Age must be 18 or greater than 18",
                                  ),
                              ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
              >

              <Input
                    type="number"
                    value={formValues.age}
                    onChange={(e) => {
                      const numericAge = Number(e.target.value);
                      setFormValues({...formValues, age: numericAge || 0});
                    }}
                />
              </Form.Item>
            </div>
          </div>
          </div>
          <div className={styles.ButtonAlignment}>
            <Button
                type="primary"
                htmlType="submit"
                className={styles.ButtonStyle}
            >
              Apliko tani
            </Button>
          </div>
        </Form>
      </div>
  );
};

export default VolunteerForm;
